import React, { useState, useEffect } from "react"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import Head from "../components/head"
import PageHeading from "../components/pageHeading"
import Text from "../components/Text"
import Form from "../components/Form"

import testimonials from "../cms/testimonials"

export default () => {
  const [inputText, setInputText] = useState("")
  const handleInputText = e => setInputText(e.target.value)

  const [name, setName] = useState("")
  const handleName = e => setName(e.target.value)

  const toEmail = testimonials.email

  const [mailTo, setMailTo] = useState("mailto:" + toEmail)

  useEffect(() => {
    setMailTo(`mailto:${toEmail}?subject=Praise for One Cello, One Planet&body="${inputText}" -${name}`)
  }, [inputText, name, toEmail])


  const [formOpen, setFormOpen] = useState(false)

  return (
    <Layout>
      <Head title="Praise for One Cello, One Planet" />
  
      <PageHeading text="Praise for One Cello, One Planet" centered />
  
      <p
        css={css`
          margin-bottom: 1.5rem;
          text-align: center;
          ${!formOpen && "margin-bottom: 3.5rem;"}
          line-height: 1.5;

          @media (max-width: 575px) {
            br {
              display: none;
            }
          }
        `}
      >
        If you've attended a <strong>One Cello, One Planet</strong> concert, <br />please share your impressions by clicking <button
          css={css`
            text-decoration: underline;
            cursor: pointer;
            display: inline;
            background: transparent;
            border: none;
          `}
          onClick={() => setFormOpen(!formOpen)}
        >
          here
        </button>
        .
      </p>
  
      {formOpen && 
        <Form
          style={css`
            max-width: 40rem;
            margin: 0 auto;
            margin-bottom: 4rem;

            input, textarea {
              margin-bottom: .5rem;
            }

            textarea {
              min-height: 5rem;
              width: 100%;
            }
          `}
        >
          <label htmlFor="name">Name</label>
          <input id="name" type="text" required 
            value={name}
            onChange={handleName}
          />

          <label htmlFor="testimonial-text">Impressions</label>
          <textarea id="testimonial-text" type="text" required
            value={inputText}
            onChange={handleInputText}
          />

          <a href={mailTo}>Send ⟶</a>
        </Form>
      }
  
      {testimonials.testimonials.map(data => (
        <div
          key={data.quote + data.name}
          css={css`
            margin-bottom: 1rem;
          `}
        >
          <Text
            style={css`
              font-style: italic;
              margin-bottom: 1rem;
              font-size: 1.3rem;
            `}
          >
            {data.quote}
          </Text>
  
          <Text
            style={css`
              color: var(--text-light-gray);
              margin-left: 40vw;
              font-size: 1.1rem;
            `}
          >
            {"- " + data.name}
          </Text>
        </div>
      ))}
    </Layout>
  )
}